import {Module} from 'vuex';
import {api} from '~/plugins/api';
import {RootState} from '~/store';


export interface AppState {
  showNavigation: boolean,
  isLoading: boolean,
  title: string,
  titleShort: string,
  openRequests: number,
  openTickets: number,
  openTasks: number,
  openOrders: number,
  overdueOrders: number,
}

export const appStore: Module<AppState, RootState> = {
  namespaced: true,
  state: {
    showNavigation: window.localStorage.getItem('showNavigation') !== 'false',
    isLoading: false,
    title: 'LunaTech Mentoring System',
    titleShort: 'LTMS',
    openRequests: 0,
    openTickets: 0,
    openTasks: 0,
    openOrders: 0,
    overdueOrders: 0,
  },
  mutations: {
    START_REQUEST: (state) => {
      state.openRequests++;
    },
    STOP_REQUEST: (state) => {
      state.openRequests--;
    },
    SET_NAVIGATION: (state, showNavigation) => {
      window.localStorage.setItem('showNavigation', showNavigation);
      state.showNavigation = showNavigation;
    },
    SET_OPEN_TICKETS: (state, openTickets: number) => {
      state.openTickets = openTickets;
    },
    SET_OPEN_TASKS: (state, openTasks: number) => {
      state.openTasks = openTasks;
    },
    SET_OPEN_ORDERS: (state, openOrders: number) => {
      state.openOrders = openOrders;
    },
    SET_OVERDUE_ORDERS: (state, overdueOrders: number) => {
      state.overdueOrders = overdueOrders;
    },
  },
  actions: {
    startRequest({commit}) {
      commit('START_REQUEST');
    },
    stopRequest({commit}) {
      commit('STOP_REQUEST');
    },
    toggleNavigation({commit, state}) {
      commit('SET_NAVIGATION', !state.showNavigation);
    },
    setNavigation({commit}, isOpen: boolean) {
      commit('SET_NAVIGATION', isOpen);
    },
    async fetchOpenTickets(context) {
      try {
        const response = await api.get('/api/tickets/open');
        context.commit('SET_OPEN_TICKETS', response.data.openTickets);
      } catch (e) {
        console.log(e);
      }
    },
    async fetchMyOpenTasks(context) {
      try {
        const response = await api.get('/api/tasks/my-tasks/open');
        context.commit('SET_OPEN_TASKS', response.data.openTasks);
      } catch (e) {
        console.log(e);
      }
    },
    async fetchOpenOrders(context) {
      try {
        const response = await api.get('/api/orders/counts');
        context.commit('SET_OPEN_ORDERS', response.data.openOrders);
        context.commit('SET_OVERDUE_ORDERS', response.data.overdueOrders);
      } catch (e) {
        console.log(e);
      }
    },
    increaseOpenTickets(context) {
      context.commit('SET_OPEN_TICKETS', context.state.openTickets + 1);
    },
    decreaseOpenTickets(context) {
      context.commit('SET_OPEN_TICKETS', context.state.openTickets - 1);
    },
  },
  getters: {},
};
